import { useEffect, useRef } from "react";
import { modalInstance, DatePicker } from "@fundrecs/ui-library";
import moment from "moment";
import { observer } from "mobx-react-lite";
import { useParams, useNavigate } from "react-router-dom";
import { ROUTES } from "utils/enums";
import { useStore } from "store/Store";
import { StatusCell, StatusCellText } from "../../../ag-grid/StatusCell/StatusCell";
import { VerticalMenu } from "../../../ag-grid/verticalMenu/VerticalMenu";
import { Table } from "../../../ag-grid/Ag-grid";
import { NoWorkflowRunResults } from "../../NoWorkflows";
import { MODAL_IDS } from "../../../../utils/workflows/enums";
import { RefreshControl } from "../../../reusable/RefreshControl/RefreshControl";
import { ResultFileListCell, ResultFileListCellText } from "../../WorkflowRunResults/tabs/ResultFileListCell/ResultFileListCell";
import {
  OriginalFiles,
  RenderDestinationTypes,
  Templates,
  timeTakenFilterParams,
  durationTime,
  timeFilterParams,
  resultFilterParams,
  Time,
  originalFilesFilterParams,
  destinationTypeFilterParams,
  destinationTypeComparator,
  templatesFilterParams,
  TriggerType,
  Trigger,
  triggerTypeParams,
  triggerParams,
  triggerTypeComparator,
  triggerComparator,
  RenderSource,
  RenderSourceTypes,
  statusParams,
  sourceTypeParams,
  sourceParams,
  RenderDestination,
  destinationFilterParams,
  RenderRowsExtracted,
  rowsExtractedFilterParams,
  destinationTypes,
  destination,
  sourceTypes,
  source,
} from "components/workflows/reusable/runResults/index";
import { DownloadTable } from "components/reusable/Button/DownloadTable";
import { useSelectedTeam } from "store/hooks/useSelectedTeam";
import { useTeamId } from "store/hooks/useTeamId";

const ResultsTab = observer(({ loading, getRunResultData, runResults, date, setDate }) => {
  const navigate = useNavigate();
  const { meStore } = useStore();
  const { workflowUuid } = useParams();

  const { teamName } = useSelectedTeam();
  const teamId = useTeamId();
  const today = moment().format(meStore.getUserDateFormat());
  const reportDate = moment(date).format(meStore.getUserDateFormat());
  const workflowName = runResults ? runResults[0]?.workflowName : "";
  const fileName = `Run Results for workflow ${workflowName} ${reportDate} for ${teamName}-${today}`;

  const gridApiRef = useRef();

  useEffect(() => {
    // Show Ag-Grid spinner until data is loaded
    gridApiRef?.current?.api?.showLoadingOverlay();

    if (date) {
      const { id } = meStore.getDefaultTeamOrFirstInList();
      const defaultTeamId = teamId === null ? id : teamId;
      navigate(`${ROUTES.WORKFLOWS}/${workflowUuid}?teamId=${defaultTeamId}&date=${moment(date).format(meStore.getUserDateFormat())}`, { replace: false });
    }

    if (date && teamId) {
      getRunResultData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, teamId]);

  /**
   * Custom cell processing callback for AG Grid export to CSV.
   * Processes cell values based on the column ID for export.
   *
   * For more details refer to:
   * https://ag-grid.com/archive/26.1.0/javascript-data-grid/csv-export/#reference-csvExportParams-processCellCallback
   *
   * @param {Object} params - Parameters provided by AG Grid.
   * @param {Object} params.column - The column object containing column metadata.
   * @param {Function} params.column.getColId - Function to get the column ID.
   * @param {Object} params.node - The row node object containing row data.
   * @param {Object} params.node.data - The data of the row node.
   * @returns {string} - The processed cell value.
   */
  const processCellCallback = ({ column, node }) => {
    const columnId = column.getColId();
    const metadata = node.data?.metadata ?? [];

    // Retrieve the cell value, defaulting to an empty string if it isn't available
    const cellValue = node.data?.[columnId] ?? "";

    switch (columnId) {
      case "startTimestamp":
        return Time({ value: cellValue });
      case "state":
        return StatusCellText({ value: cellValue });
      case "result":
        return ResultFileListCellText(metadata, teamId);
      case "destinationType":
        return destinationTypes({ data: { metadata } });
      case "destination":
        return destination({ data: { metadata } });
      case "originalFile":
        return metadata.map((file) => file.fileUploadedName).join(", ");
      case "sourceType":
        return sourceTypes({ data: { metadata } });
      case "source":
        return source({ data: { metadata } });
      case "templateApplied":
        return metadata.map((meta) => meta.templateName).join(", ");
      case "rowsExtracted":
        return metadata.map((meta) => meta.rowsExtracted).join(", ");
      case "duration":
        return durationTime({ value: cellValue });
      case "triggerType":
        return TriggerType({ data: node.data });
      case "trigger":
        return Trigger({ data: node.data });
      default:
        return cellValue;
    }
  };

  return (
    <>
      <div className="pt-32 d-flex">
        <div>
          <DatePicker date={date} onDateChange={setDate} dateFormat={meStore.getUserDateFormat()} />
        </div>
        <span className="pl-16 align-self-center">
          <RefreshControl refreshing={loading} onClick={getRunResultData} />
        </span>
        <span className="align-self-center">
          <DownloadTable gridRef={gridApiRef.current} fileName={fileName} processCellCallback={processCellCallback} excludeEmpty={true} />
        </span>
      </div>

      <div className="pt-24 ">
        <LatestRunResults runResults={runResults} gridApiRef={gridApiRef} teamId={teamId} date={date} dateFormat={meStore.getUserDateFormat()} />
      </div>
    </>
  );
});

const gridOptions = {
  suppressCellFocus: true,
  pagination: true,
  paginationPageSize: 100,
  detailRowAutoHeight: true,
  suppressContextMenu: true,
  suppressRowClickSelection: true,
  noRowsOverlayComponent: NoWorkflowRunResults,
};

const LatestRunResults = ({ runResults, gridApiRef }) => {
  const onItemClick = (option) => {
    switch (option) {
      case "settings":
        modalInstance(MODAL_IDS.EDIT_WORKFLOW_SETTINGS).toggle();
        break;
      case "delete":
        modalInstance(MODAL_IDS.DELETE_WORKFLOW).toggle();
        break;
      default:
        break;
    }
  };

  const onGridReady = (params) => {
    gridApiRef.current = params;
  };

  useEffect(() => {
    gridApiRef?.current?.api?.forEachNode((node) => {
      // Update row height based on number of lines present in the metadata - if no metadata it defaults to one line which can use default height
      const metadataLines = node?.data?.metadata?.length || 1;
      if (metadataLines > 1) {
        const currentHeight = node?.rowHeight;
        node?.setRowHeight(currentHeight * metadataLines);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runResults]);

  const menuItems = [
    {
      key: "Rerun",
      label: "Rerun",
      disabled: true,
    },
  ];

  const columns = [
    {
      headerName: "Started",
      field: "startTimestamp",
      sort: "desc",
      flex: 2,
      minWidth: 150,
      valueFormatter: Time,
      filter: "agTextColumnFilter",
      filterParams: timeFilterParams,
    },
    { headerName: "Status", field: "state", cellRenderer: StatusCell, maxWidth: 150, filter: "agTextColumnFilter", filterParams: statusParams },
    {
      headerName: "Result",
      field: "result",
      flex: 2,
      minWidth: 150,
      resizable: true,
      cellRenderer: ResultFileListCell,
      detailRowAutoHeight: true,
      filter: "agTextColumnFilter",
      sortable: false,
      filterParams: resultFilterParams,
    },
    {
      headerName: "Destination type",
      field: "destinationType",
      flex: 2,
      resizable: true,
      minWidth: 150,
      sortable: false,
      cellRenderer: RenderDestinationTypes,
      filter: "agTextColumnFilter",
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        return destinationTypeComparator(valueA, valueB, nodeA, nodeB, isDescending);
      },
      filterParams: destinationTypeFilterParams,
    },
    {
      headerName: "Destination",
      field: "destination",
      flex: 2,
      resizable: true,
      minWidth: 150,
      cellRenderer: RenderDestination,
      filter: "agTextColumnFilter",
      filterParams: destinationFilterParams,
    },
    {
      headerName: "Original file",
      field: "originalFile",
      flex: 2,
      resizable: true,
      minWidth: 150,
      cellRenderer: OriginalFiles,
      filter: "agTextColumnFilter",
      sortable: false,
      filterParams: originalFilesFilterParams,
    },
    {
      headerName: "Source type",
      sortable: false,
      field: "sourceType",
      flex: 2,
      resizable: true,
      minWidth: 150,
      cellRenderer: RenderSourceTypes,
      filter: "agTextColumnFilter",
      filterParams: sourceTypeParams,
    },
    {
      headerName: "Source",
      sortable: false,
      field: "source",
      flex: 2,
      resizable: true,
      minWidth: 150,
      cellRenderer: RenderSource,
      filter: "agTextColumnFilter",
      filterParams: sourceParams,
    },
    {
      headerName: "Template applied",
      field: "templateApplied",
      flex: 2,
      resizable: true,
      minWidth: 150,
      cellRenderer: Templates,
      sortable: false,
      filter: "agTextColumnFilter",
      filterParams: templatesFilterParams,
    },
    {
      headerName: "Rows extracted",
      field: "rowsExtracted",
      flex: 2,
      resizable: true,
      minWidth: 150,
      cellRenderer: RenderRowsExtracted,
      sortable: false,
      filter: "agNumberColumnFilter",
      filterParams: rowsExtractedFilterParams,
    },
    {
      headerName: "Time taken",
      field: "duration",
      flex: 2,
      minWidth: 150,
      valueFormatter: durationTime,
      filter: "agTextColumnFilter",
      filterParams: timeTakenFilterParams,
    },
    {
      headerName: "Trigger type",
      field: "triggerType",
      flex: 2,
      resizable: true,
      minWidth: 150,
      filter: "agTextColumnFilter",
      cellRenderer: TriggerType,
      filterParams: triggerTypeParams,
      comparator: triggerTypeComparator,
    },
    {
      headerName: "Trigger",
      field: "trigger",
      flex: 2,
      resizable: true,
      minWidth: 450,
      cellRenderer: Trigger,
      filter: "agTextColumnFilter",
      filterParams: triggerParams,
      comparator: triggerComparator,
    },
    {
      headerName: "",
      field: "rowEndSettings",
      suppressMenu: true,
      rowDrag: false,
      editable: false,
      suppressSizeToFit: true,
      sortable: false,
      pinned: "right",
      width: 60,
      cellRenderer: VerticalMenu,
      cellRendererParams: { menuItems: menuItems, onItemClick: onItemClick },
    },
  ];

  return <Table columns={columns} rowData={runResults} agGridOptions={{ ...gridOptions, onGridReady }} />;
};

export { ResultsTab };
