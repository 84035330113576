import { TabsSection } from "@fundrecs/ui-library";
import styles from "./Tabs.module.scss";

const Tabs = ({ activeTabIndex, setActiveTabIndex }) => {
  return (
    <div className="pl-32 pr-32 ml-32">
      <TabsSection>
        <ul className={["nav"].join(" ")}>
          <li
            onClick={() => {
              setActiveTabIndex(0);
            }}
          >
            <span className={["tab-link text-sm text-medium", `${activeTabIndex === 0 ? [`tab-link-active ${styles.active}`] : styles.inactive}`].join(" ")}>
              Results
            </span>
          </li>
          <li
            onClick={() => {
              setActiveTabIndex(1);
            }}
          >
            <span className={["tab-link text-sm text-medium", `${activeTabIndex === 1 ? [`tab-link-active ${styles.active}`] : styles.inactive}`].join(" ")}>
              Queue
            </span>
          </li>
          <li
            onClick={() => {
              setActiveTabIndex(2);
            }}
          >
            <span className={["tab-link text-sm text-medium", `${activeTabIndex === 2 ? [`tab-link-active ${styles.active}`] : styles.inactive}`].join(" ")}>
              Overview
            </span>
          </li>
          <li>
            <span className={["tab-link text-sm text-medium", styles.disabled].join(" ")}>Audit log</span>
          </li>
          <li>
            <span className={["tab-link text-sm text-medium", styles.disabled].join(" ")}>Uploaded files</span>
          </li>
        </ul>
      </TabsSection>
    </div>
  );
};

export { Tabs };
