import { isUserAuthorized } from "components/AuthorizationWrapper";
import { rolesStore } from "store/Store";
import { AUTHORITIES } from "utils/enums";
import { STATUS_VALUES } from "utils/templates/enums";

const getMenuItems = ({ teamId, data }) => {
  const { status } = data;

  let menuOptions = [];

  switch (status) {
    case STATUS_VALUES.PENDING:
      menuOptions.push(viewOption(teamId));
      menuOptions.push(cloneOption(teamId));
      menuOptions.push(deleteOption(teamId, data));
      menuOptions.push(exportOption(teamId));
      break;
    case STATUS_VALUES.REJECTED:
      menuOptions.push(editOption(teamId));
      menuOptions.push(cloneOption(teamId));
      menuOptions.push(deleteOption(teamId, data));
      menuOptions.push(exportOption(teamId));
      break;
    case STATUS_VALUES.PUBLISHED:
      menuOptions.push(viewOption(teamId));
      menuOptions.push(cloneOption(teamId));
      menuOptions.push(deleteOption(teamId, data));
      menuOptions.push(exportOption(teamId));
      break;
    case STATUS_VALUES.APPROVED:
      menuOptions.push(viewOption(teamId));
      menuOptions.push(cloneOption(teamId));
      menuOptions.push(deleteOption(teamId, data));
      menuOptions.push(exportOption(teamId));
      break;
    case STATUS_VALUES.SUBMITTED:
      menuOptions.push(viewOption(teamId));
      menuOptions.push(cloneOption(teamId));
      menuOptions.push(exportOption(teamId));
      menuOptions.push(deleteOption(teamId, data));
      break;
    case STATUS_VALUES.DRAFT:
      menuOptions.push(editOption(teamId));
      menuOptions.push(cloneOption(teamId));
      menuOptions.push(deleteOption(teamId, data));
      menuOptions.push(exportOption(teamId));
      break;
    default:
      break;
  }

  return menuOptions;
};

const viewOption = (teamId) => {
  return {
    key: "viewRow",
    label: "View",
    visible: isUserAuthorized({ teamId, allRequired: rolesStore.getActions([AUTHORITIES.TEMPLATE_VIEW]) }),
  };
};

const editOption = (teamId) => {
  return {
    key: "editRow",
    label: "Edit",
    visible: isUserAuthorized({ teamId, allRequired: rolesStore.getActions([AUTHORITIES.TEMPLATE_EDIT]) }),
  };
};

const deleteOption = (teamId) => {
  return {
    key: "deleteRow",
    label: "Delete",
    disabled: false,
    visible: isUserAuthorized({ teamId, allRequired: rolesStore.getActions([AUTHORITIES.TEMPLATE_DELETE]) }),
  };
};

const exportOption = (teamId) => {
  return {
    key: "exportRow",
    label: "Export",
    disabled: true,
    visible: isUserAuthorized({ teamId, allRequired: rolesStore.getActions([AUTHORITIES.TEMPLATE_EDIT]) }),
  };
};

const cloneOption = (teamId) => {
  return {
    key: "cloneRow",
    label: "Clone",
    visible: isUserAuthorized({ teamId, allRequired: rolesStore.getActions([AUTHORITIES.TEMPLATE_CREATE]) }),
  };
};

export { getMenuItems };
