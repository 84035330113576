import { makeAutoObservable, toJS } from "mobx";
import { fusionDataApi } from "../api";
import { uiStore } from "./Store";

const api = fusionDataApi.templates;

class TemplatesStore {
  isLoading = false;
  templates = [];
  templatesLinkedtoOutputMapping = [];
  templatesLinkedtoOutputMappings = [];

  constructor() {
    makeAutoObservable(this);
  }

  getTemplates = () => {
    return toJS(this.templates);
  };

  getTemplatesLinkedToOutputMapping = () => {
    return toJS(this.templatesLinkedtoOutputMapping);
  };

  getTemplatesLinkedToOutputMappings = () => {
    return toJS(this.templatesLinkedtoOutputMappings);
  };

  setTemplates = (value) => {
    this.templates = value;
  };

  setLinkedTemplatesForOutputMapping = (value) => {
    this.templatesLinkedtoOutputMapping = value;
  };

  updateTemplateinStore(template) {
    const index = this.templates.findIndex((index) => index.templateUuid === template.templateUuid);
    this.templates[index] = template;
  }

  loadTemplates = (teamId) => {
    this.isLoading = true;
    return api
      .getTemplates(teamId)
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200) {
          this.setTemplates(response.data);
          return { ...response, ...{ success: true } };
        } else {
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        this.isLoading = false;
        uiStore.addNotification("error", "Unable to get templates! Please try again. ");
      });
  };

  getTemplateByUuidAndTeamId = (templateUuid, teamId) => {
    this.isLoading = true;
    return api
      .getRequiredTemplate({ templateUuid, teamId })
      .then((response) => {
        this.isLoading = false;
        return { ...response, success: response.status === 200 };
      })
      .catch((error) => {
        this.isLoading = false;
        uiStore.addNotification("error", `Unable to fetch Template. ${error}`);
      });
  };

  updateTemplate = (teamId, request) => {
    this.isLoading = true;
    const { templateUuid } = request;
    return api
      .updateTemplate(teamId, templateUuid, request)
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", "Template Settings successfully updated!");
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", "Unable to change Template Settings! Please try again");
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", "Unable to change Template Settings! Please try again");
      });
  };

  saveDraftTemplate = (teamId, templateUuid) => {
    this.isLoading = true;
    return api
      .saveDraftTemplate(teamId, templateUuid)
      .then((response) => {
        this.isLoading = false;
        const { status, data } = response;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", `Template ${data?.name} successfully saved`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to save template ${data?.name}! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", "Unable to save template! Please try again");
      });
  };

  approveTemplate = (teamId, templateUuid) => {
    this.isLoading = true;
    return api
      .approveTemplate(teamId, templateUuid)
      .then((response) => {
        this.isLoading = false;
        const { status, data } = response;
        const { name } = data;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", `Template '${name}' successfully approved`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to approve template '${name}'! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", "Unable to approve template! Please try again");
        return { ...{ success: false } };
      });
  };

  publishTemplate = (teamId, templateUuid) => {
    this.isLoading = true;
    return api
      .publishTemplate(teamId, templateUuid)
      .then((response) => {
        this.isLoading = false;
        const { status, data } = response;
        const { name } = data;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", `Template '${name}' successfully published`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", "Unable to publish template! Please try again");
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", "Unable to publish template! Please try again");
        return { ...{ success: false } };
      });
  };

  submitTemplate = (teamId, templateUuid) => {
    this.isLoading = true;
    return api
      .submitTemplate(teamId, templateUuid)
      .then((response) => {
        this.isLoading = false;
        const { status, data } = response;
        const { name } = data;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", `Template '${name}' successfully submitted for approval`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to submit template '${name}' for approval! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", "Unable to submit template! Please try again");
        return { ...{ success: false } };
      });
  };

  rejectTemplate = (teamId, templateUuid) => {
    this.isLoading = true;
    return api
      .rejectTemplate(teamId, templateUuid)
      .then((response) => {
        this.isLoading = false;
        const { status, data } = response;
        const { name } = data;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", `Template '${name}' successfully rejected`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to reject template '${name}'! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", "Unable to reject template! Please try again");
        return { ...{ success: false } };
      });
  };

  cloneTemplate = (teamId, templateUuid) => {
    this.isLoading = true;
    return api
      .cloneTemplate(teamId, templateUuid)
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", "Template successfully cloned");
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", "Unable to clone template! Please try again");
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", "Unable to clone template! Please try again");
        return { ...{ success: false } };
      });
  };

  deleteTemplate = (teamId, templateUuid, name) => {
    this.isLoading = true;
    return api
      .deleteTemplate(teamId, templateUuid)
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", `"Template '${name}' successfully deleted"`);
          return { ...response, ...{ success: true } };
        }
      })
      .catch((errorResponse) => {
        this.isLoading = false;
        if (Object.keys(errorResponse).length === 0) {
          uiStore.addNotification("error", "Unable to delete template! Please refresh & try again");
          return { ...{ success: false, ...{ fieldValue: [] }, ...{ templateName: name } } };
        } else {
          return { ...{ success: false, ...errorResponse, ...{ templateName: name } } };
        }
      });
  };

  loadTemplatesLinkedToOutputMapping = (teamId, outputMappingUuid) => {
    this.isLoading = true;
    return api
      .getTemplatesLinkedToOutputMapping(teamId, outputMappingUuid)
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200) {
          this.setLinkedTemplatesForOutputMapping(response.data);
          return { ...response, ...{ success: true } };
        } else {
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", "Unable to get templates! Please try again. ");
      });
  };

  loadTemplatesLinkedToOutputMappings = (teamId, outputMappingUuids) => {
    this.isLoading = true;
    return api
      .getTemplatesLinkedToOutputMappings(teamId, outputMappingUuids)
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200) {
          this.setLinkedTemplatesForOutputMapping(response.data);
          return { ...response, ...{ success: true } };
        } else {
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", "Unable to get templates! Please try again. ");
      });
  };

  explainTemplate = (teamId, templateUuid) => {
    return api
      .explainTemplate(teamId, templateUuid)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", "Unexpected error, unable to explain this Template.");
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        uiStore.addNotification("error", "Unexpected error, unable to explain this Template.");
        return { success: false };
      });
  };
}
export { TemplatesStore };
