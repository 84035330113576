export const API_BASE_URL = "/api";
// in production this should be much smaller
export const API_REQUEST_TIMEOUT_MS = 30000;

// 600000ms equals 10 minutes
export const API_REQUEST_TIMEOUT_DOWNLOAD_FILE_MS = 600000;

export const API_ENDPOINTS = {
  LOOKUPS: {
    TABLES: "/tables",
    ROWS: "/rows",
    BULK: "/bulk-operation/status-change/",
  },
  FUSION_ADMIN: {
    ME: "/users/me",
    UPDATE_ME: "/withBody/users/updateMe",
    ROLES: "/roles",
    CONNECTIONS_SUMMARY: "/unrestricted/connections?projection=summary",
    GET_FRONTEND_PROPERTIES: "frontend-properties",
    LIST_TEAMS: "/teams",
  },

  TBUI: {
    DRAFT: "draft",
    UPDATE_PARSE_FILE: "updateParseFile",
    UPDATE_SAMPLE_FILE: "updateSampleFile",
    DELETE_COMMAND: "deleteCommand",
    UPDATE_COMMAND: "updateCommand",
    UNDO: "undo",
    GET_RESULT_AND_STACK: "getResultAndStack",
    COMMANDS_LIST: "commandsList",
    GET_REQUIRED_TEMPLATE_MAPPING: "getRequiredTemplateMapping",
    SAVE_TEMPLATE_MAPPINGS: "saveTemplateMappings",
    SUBMIT_TEMPLATE_FOR_APPROVAL: "submitTemplateForApproval",
    APPROVE_TEMPLATE: "approveTemplate",
    REJECT_TEMPLATE: "rejectTemplate",
    GET_TMOS_FUSION: "tmos",
    UPDATE_TEMPLATE_TYPE: "updateTemplateType",
    CREATE_NEW_TEMPLATE: "newTemplate",
    GET_REQUIRED_TEMPLATE: "getRequiredTemplate",
  },

  ME: {
    GET_ME: "/",
  },
  WORKFLOWS: {
    WORKFLOWS: "workflows",
    STEPS: "workflowSteps",
    WORKFLOW_RUN_RESULTS: "workflow-runs",
    WORKFLOW_QUEUE: "workflow-queue",
  },
  OUTPUT_MAPPINGS: {
    OUTPUT_MAPPINGS: "output-mappings",
    SHARE_OUTPUT_MAPPING: "share",
    OUTPUT_MAPPINGS_ADDITIONAL_DATA: "output-mappings?includeAssignedToWorkflows=true&includeMappedToTemplates=true",
    APPROVED_OUTPUT_MAPPINGS: "output-mappings?status=Approved",
    OUTPUT_MAPPING_ADDITIONAL_DATA: "?includeAssignedToWorkflows=true&includeMappedToTemplates=true",
    COLUMNS: "columns",
    APPROVE_OUTPUT_MAPPING: "approve",
    CLONE_OUTPUT_MAPPING: "clone",
  },
  TEMPLATES: {
    TEMPLATES: "getTemplates",
    TEMPLATE: "template",
    UPDATE_TEMPLATE_DETAILS: "updateTemplateDetails",
    SAVE_DRAFT_TEMPLATE: "saveDraft",
    CLONE_TEMPLATE: "cloneTemplate",
    DELETE_TEMPLATE: "deleteTemplate",
    APPROVE_TEMPLATE: "approveTemplate",
    PUBLISH_TEMPLATE: "publishTemplate",
    SUBMIT_TEMPLATE: "submitTemplate",
    REJECT_TEMPLATE: "rejectTemplate",
    TEMPLATES_LINKED_TO_OUTPUT_MAPPING: "getTemplatesLinkedToTmo",
    TEMPLATES_LINKED_TO_OUTPUT_MAPPINGS: "getTemplatesLinkedToTmos",
    EXPLAIN_TEMPLATE: "explainTemplate",
  },
  TENANT_CONFIG: {
    GET_TENANT_CONFIG: "tenant",
  },
  IMPORT_RULES: {
    GET_IMPORT_RULES: "import-management",
  },
  RECONCILIATIONS: {
    REC_TYPES: "recTypes",
  },
};
