import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Text,
  modalInstance,
  Dropdown,
  DropdownButton,
  DropdownButtonText,
  IconDownArrow,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  TeamAvatar,
  ItemBody,
} from "@fundrecs/ui-library";
import { useStore } from "store/Store";
import { useTeamId } from "store/hooks/useTeamId";
import { MODAL_IDS } from "utils/mappings/enums";
import { ROUTES } from "utils/enums";
import styles from "./ShareOutputMappingModal.module.scss";

const ShareOutputMappingModal = ({ shareOutputMappingUuid }) => {
  const { meStore, uiStore, outputMappingsStore } = useStore();
  const teamdId = useTeamId();

  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedTeamIndex, setSelectedTeamIndex] = useState(null);
  const [shareWithTeam, setShareWithTeam] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const clearModalState = () => {
    setSelectedTeam(null);
    setSelectedTeamIndex(null);
    setShareWithTeam(false);
    setRequesting(false);
  };

  const successToastWithTeamLink = (team) => {
    const { name, id } = team;
    return (
      <>
        {`Output mapping successfully shared with ${name} team workspace`}
        <br />
        <Link className={styles.linkStyle} to={`${ROUTES.OUTPUT_MAPPINGS}?teamId=${id}`}>
          Go to team workspace
        </Link>
      </>
    );
  };

  const handleShare = () => {
    setRequesting(true);
    const notificationId = uiStore.addNotification("status", `Sharing output mapping with ${selectedTeam.name} team workspace...`);

    setTimeout(async () => {
      uiStore.removeNotification(notificationId);
      const response = await outputMappingsStore?.shareOutputMapping(
        teamdId,
        shareOutputMappingUuid,
        selectedTeam,
        [selectedTeam.id],
        successToastWithTeamLink(selectedTeam)
      );
      const { success } = response;
      if (success) {
        !shareWithTeam && modalInstance(MODAL_IDS.SHARE_OUTPUT_MAPPING).toggle();
        clearModalState();
      } else {
        setRequesting(false);
      }
    }, 2000);
  };

  return (
    <Modal modalId={MODAL_IDS.SHARE_OUTPUT_MAPPING} size="lg">
      <ModalBody>
        <div className="w-100 pb-32">
          <div className="d-flex">
            <ModalHeader heading="Share output mapping" />
            <button
              type="button"
              className="btn-close ms-auto"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                clearModalState();
                modalInstance(MODAL_IDS.SHARE_OUTPUT_MAPPING).hide();
              }}
            />
          </div>
          <div className="pt-20 pb-8">
            <Text variant="primary" weight="medium" size="sm">
              Select team workspace to share output mapping with
            </Text>
          </div>
          <div>
            <Dropdown>
              <DropdownButton size="lg">
                <DropdownButtonText>
                  <ItemBody>
                    {
                      <>
                        {selectedTeam === null ? (
                          <Text size="sm" weight="regular">
                            Select
                          </Text>
                        ) : (
                          <div className="d-flex">
                            <TeamAvatar text={selectedTeam?.name} size="xs" />
                            <div className="ml-12">
                              <Text size="sm" weight="medium">
                                {selectedTeam?.name}
                              </Text>
                            </div>
                          </div>
                        )}
                      </>
                    }
                  </ItemBody>

                  <IconDownArrow className="btn-md-svg" />
                </DropdownButtonText>
              </DropdownButton>
              <DropdownList classes={styles.dropdownList}>
                {meStore?.getTeams()?.map((team, index) => {
                  return (
                    <DropdownListItem
                      key={index}
                      onClick={() => {
                        setSelectedTeam(team);
                        setSelectedTeamIndex(index);
                      }}
                    >
                      <DropdownItem active={selectedTeamIndex} index={index}>
                        <TeamAvatar text={team?.name} size="xs" />
                        <div className="align-self-center ml-12">
                          <Text size="sm" weight="medium">
                            {team?.name}
                          </Text>
                        </div>
                      </DropdownItem>
                    </DropdownListItem>
                  );
                })}
              </DropdownList>
            </Dropdown>
          </div>
        </div>
      </ModalBody>
      <div className={["d-flex", `${styles.modalFooter}`].join(" ")}>
        <div className="d-flex w-100">
          <input className="form-check-input mt-8" type="checkbox" checked={shareWithTeam} onChange={() => setShareWithTeam(!shareWithTeam)} />
          <div className="align-self-center ml-8">
            <Text size="sm" weight="regular">
              Share with another team workspace
            </Text>
          </div>
        </div>
        <div className="d-flex flex-shrink-1 bd-highlight">
          <Button
            color="tertiary"
            onClick={() => {
              clearModalState();
              modalInstance(MODAL_IDS.SHARE_OUTPUT_MAPPING).toggle();
            }}
          >
            <Text size="sm">Cancel</Text>
          </Button>
          <span className="ml-12">
            <Button
              color="primary"
              onClick={() => {
                handleShare();
              }}
              disabled={selectedTeam === null || requesting === true}
            >
              <Text size="sm">Share</Text>
            </Button>
          </span>
        </div>
      </div>
    </Modal>
  );
};

export { ShareOutputMappingModal };
